import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Timeline from '../components/Timeline';
import Nav from '../components/Nav';
import ScrollToTop from '../components/ScrollToTop';
import PageScroll from '../components/PageScroll';

import Welcome from './Welcome';
import Career from './Career';
import GameDev from './GameDev';
import AppDev from './AppDev';

import JanitorDefenseGroup from './games/JanitorDefenseGroup';
import WinLoseBanana from './games/WinLoseBanana';

import SocialDistancingMovies from './apps/SocialDistancingMovies';
import GodotHub from './apps/GodotHub';
import SteamEasyDownload from './apps/SteamEasyDownload';

const AnimatedRoutes = () => {
    const location = useLocation();

    const goToTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };
    
    return (
        <>
        <Timeline />
        <Nav goToTop={goToTop} />
        <main className="">
        <PageScroll>
            <Routes location={location} key={location.pathname}>
                <Route path="/career" element={<Career />} />
                <Route path="/game/janitordefensegroup" element={<JanitorDefenseGroup />} />
                <Route path="/game/winlosebanana" element={<WinLoseBanana />} />
                <Route path="/game" element={<GameDev />} />
                <Route path="/app/godothub" element={<GodotHub />} />
                <Route path="/app/sdmovies" element={<SocialDistancingMovies />} />
                <Route path="/app/steameasydownload" element={<SteamEasyDownload />} />
                <Route path="/app" element={<AppDev />} />
                <Route path="*" element={<Welcome />} />
            </Routes>
        </PageScroll>
        </main>
        <ScrollToTop goToTop={goToTop} />
        </>
    )
}

export default AnimatedRoutes;