import React from 'react';
import { Link } from 'react-router-dom';

import Article from '../../components/Article';
import TableOfContents from '../../components/TableOfContents';
import Image from '../../components/Image';
import IconLink from '../../components/IconLink';

import {LinkIcon, ReactIcon, FirebaseIcon} from '../../components/Icons';

import sdmHome from '../../images/apps/sdm/SDM_HomeScreen.png';
import sdmCreate from '../../images/apps/sdm/SDM_CreateRoom.png';
import sdmRoom from '../../images/apps/sdm/SDM_Room.png';
import sdmMyRooms from '../../images/apps/sdm/SDM_MyRooms.png';
import sdmMovie from '../../images/apps/sdm/SDM_Movie.png';

const toc = [
    {"label":"About", "id": "about"},
    {"label":"How to Use", "id": "howto"},
]

const SocialDistancingMovies = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="about" label="About">
        <h2>Social Distancing Movies</h2>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://sdmovies.cx3tech.com/" target="_blank" icon={LinkIcon} className="flex-none">View Website</IconLink>
        </div>
        <Image src={sdmHome} alt="Social Distancing Movies" />
        <h3>Built With</h3>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://react.dev/" target="_blank" icon={ReactIcon} className="flex-none">React</IconLink>
          <IconLink to="https://firebase.google.com/" target="_blank" icon={FirebaseIcon} className="flex-none">Firebase</IconLink>
        </div>
        <p>
          Towards the end of 2019 / beginning of 2020 I was no longer able to spend time with my friends in person for… reasons. To stay in touch, 
          we decided to have a weekly movie night online. There were a few ways we could watch movies together on the mainstream streaming platforms 
          or doing a countdown over voice chat and we would all hit play at the same time. The problem is we kept running into an issue where if 
          somebody would have internet issues it would put us out of synch which makes it hard to heckle the movie or laugh together. To solve this 
          problem, I decided to create Social Distancing Movies (or SD Movies for short).
        </p>
        <p>
          With SD Movies, you are able to create a room for all of your friends to join. This room will have the movie file associated to it which 
          everybody who joins can download which makes the movie available offline. Once it’s movie time, select the downloaded move file and hit play. 
          Anybody who is in the room will also start playing at the same time. Since the movie is a downloaded file instead of streaming there are 
          no issues with buffering at all. It uses the bare minimum web calls by just updating everybody when there is a change to the video playing 
          state along with a timestamp of when it started playing so if somebody joins late and the movie is already playing it will just start them 
          up exactly where everybody else is.
        </p>
    </Article>
    <Article id="howto" label="How to Use">
        <h3>How To Use Social Distancing Movies</h3>
        <h4>1. Create a Room</h4>
        <p>
          The first thing you will need to do is create a new room for everybody to join. To do this, click on the <Link to="https://sdmovies.cx3tech.com/create">Create a Room</Link> button 
          on the home page or in the top right corner. This will bring you to the Create a Room page. Give the room a name, <i className="text-slate-400">I like to use my favorite quote from the movie</i>, and then enter the 
          name of the movie that you will be watching.
        </p>
        <p>
          In the Movie File section, choose if you would like to provide a link to the movie file through Dropbox, Google Drive or a direct link
          for people to download the movie from. Once the Progress section shows all green checkmarks, click on the Create Room button on the bottom right.
        </p>  
        <Image src={sdmCreate} alt="Create a Room" />
        <p>
          Once the room is created, it will bring you into your newly created room. Now all you need to do is share out the link with your friends, <i className="text-slate-400">or enemies... 
          no judgement here</i>, by clicking on the link icon in the top right or copying the link out of the address bar.
        </p> 
        <Image src={sdmRoom} alt="Room" />
        <h4>2. Start the Movie</h4>
        <p>
          It's movie time! When you're ready to start the movie, return to your room by returning to the same link form earlier or clicking on <Link to="https://sdmovies.cx3tech.com/room">My Rooms</Link> in
          the top right of the navigation bar and then selecting View on the room you would like to go to.
        </p>
        <Image src={sdmMyRooms} alt="My Rooms" />
        <p>
          Once back in your room, click on the Select Movie File button and browse for the movie the downloaded movie file on your computer. This will load the local file and open 
          up the video player. You are now ready to watch your movie. Click on play and the movie will begin playing for everybody. Don't worry if everybody is not ready yet, if 
          somebody joins later it will start them at the same place everybody else is at.
        </p>
        <Image src={sdmMovie} alt="Movie Time" />
    </Article>
    </>
  )
}

export default SocialDistancingMovies;