import React from 'react';

import Article from '../../components/Article';
import TableOfContents from '../../components/TableOfContents';
import Image from '../../components/Image';
import IconLink from '../../components/IconLink';

import {PDFIcon} from '../../components/Icons';

import jdgStart from '../../images/games/jdg/JDG_Start.png';
import jdgCombat from '../../images/games/jdg/JDG_Combat.png';
import jdgContract from '../../images/games/jdg/JDG_Contract.png';
import jdgHQ from '../../images/games/jdg/JDG_HQ.png';
import jdgPlayer from '../../images/games/jdg/JDG_Player.png';

const toc = [
  {"label":"About", "id": "about"},
  {"label":"Gameplay Summary", "id": "summary"},
  {"label":"The Player", "id": "player"},
  {"label":"Headquarters", "id": "headquarters"},
  {"label":"Contracts", "id": "contracts"},
  {"label":"Promotion Challenges", "id": "promotion"},
  {"label":"Status", "id": "status"},
]

const JanitorDefenseGroup = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="about" label="About">
        <h2>Janitor Defense Group</h2>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://www.dropbox.com/scl/fi/xztf5nwkreda3seifxbgt/JanitorDefenseGroup_GDD.pdf?rlkey=lmgg0gwysxcu7xb8z6jndqcib&dl=1" target="_blank" icon={PDFIcon} className="flex-none">GDD</IconLink>
          <IconLink to="https://www.dropbox.com/scl/fi/9md3cb2aaozqhvd502dph/JanitorDefenseGroup_PitchDeck.pdf?rlkey=lkzbq6znym0stz99rcsw9s3n1&dl=1" target="_blank" icon={PDFIcon} className="flex-none">Pitch Deck</IconLink>
        </div>
        <Image src={jdgStart} alt="Janitor Defense Group" />
        <p>
          As a member of the <b>Janitor Defense Group</b>, you will be tasked with the cleanup of the entities that form from 
          negative energy built up during the day in office buildings. Using upgradeable and moddable cleaning supplies, 
          such as brooms, mops and disinfectants, go toe-to-toe with entities to earn work experience and get paid for your hard work.
        </p>
        <h3>TLDR;</h3>
        <p>Choose contract &gt; Cleanup entities &gt; Upgrade stats, weapons &gt; Repeat until promoted to the top</p>
    </Article>
    <Article id="summary" label="Summary">
        <h3>Gameplay Summary</h3>
        <p>
          Janitor Defense Group is a hub-based action roleplaying game, taking from the combat styles of Souls-like games in a much more 
          casual format. From the Janitor Defense Group Headquarters, the hub area, you will be able to choose a contract to go on which 
          will determine the level of difficulty and rewards you can earn for completing the contract. Defeating entities while out on 
          these contracts will earn you work experience which can be used to go for a new promotion. 
        </p>
        <p>
          A promotion will unlock harder difficulties 
          and new supplies / upgrades. In order to get officially promoted you will need to take on the Promotion Challenge which will contain 
          a difficult boss fight. In between contracts you will be able to return to the base to use the money earned from contracts to upgrade 
          weapons and buy any new supplies you may have unlocked.
        </p>
    </Article>
    <Article id="player" label="The Player">
        <Image src={jdgCombat} alt="The Player" />
        <h3>The Player</h3>
        <p>
          As a member of the Janitor Defense Group, you will be starting from the bottom and working your way up to the top by earning money and 
          workplace experience while completing contracts. When you have gained enough workplace experience, you will be able to go for to work 
          your way up the ranks. The job titles you can earn are:
        </p>
        <p className="italic text-gray-400">Jr. Janitor, Janitor, Sr. Janitor, Custodial Supervisor, Manager of Custodial Arts, Manager of the Manager of Custodial Arts, The Sub-Boss</p>
        <p>
          Each new job title will unlock harder challenges, new weapons, modifications for your weapons, and more. The workplace experience can also be used to increase 
          the players stats to improve their cleaning abilities. The player stats are:
        </p>
        <ul className="text-base">
          <li><b>Strength</b>: Increases base damage with melee supplies</li>
          <li><b>Dexterity</b>: Increases base damage of ranged weapons</li>
          <li><b>Vigor</b>: Increases base damage of ranged weapons</li>
          <li><b>Stamina</b>: Increases base stamina</li>
          <li><b>Chemicals</b>: Increases potency of chemical cleaner mods on supplies<br/>Note: Some chemicals require a specific level in chemicals in order to use</li>
        </ul>
        <p>
          The player will be able choose from a variety of janitorial supplies to use while out on a contract to clean up the entities. Each of these 
          supplies will have their own unique style of combat and the player should choose wisely which ones to take when going on a contract based 
          off of what entities they know will be there and the tasks that they will need to complete.
        </p>
    </Article>
    <Article id="headquarters" label="Headquarters">
        <Image src={jdgHQ} alt="Headquarters" />
        <h3>Headquarters</h3>
        <p>
          The JDG Headquarters is the main hub area where the player will start and return to in between completing contracts. This is where the 
          player will be able to accept new contracts, upgrade and modify their supplies, check on their characters workplace experience towards leveling up and level up stats.
        </p>
    </Article>
    <Article id="contracts" label="Contracts">
        <Image src={jdgContract} alt="Contracts" />
        <h3>Contracts</h3>
        <p>
          Contracts are the randomized missions that the player will go on. From the headquarters, they will be able to browse from a set of level appropriate contracts 
          that are generated for them. These contracts will clearly state the difficulty level and what they will encounter when they go on this contract as well as 
          the tasks they will need complete to fulfill the contract and the reward for completing the contract. Some of these contracts may contain bonus tasks as well 
          which will provide a secondary challenge to the contract and provide bonus rewards for completing them. When a contract has been accepted, the player will have 
          a final chance to choose their loadout of supplies to take with them before heading out on the contract. Once ready, they can head out to complete the contract.
        </p>
    </Article>
    <Article id="promotion" label="Promotion Challenges">
        <h3>Promotion Challenges</h3>
        <p>
          When a player has earned enough workplace experience they will be able to take on a Promotion Challenge to earn their next rank at JDG. These challenges will 
          appear in a separate section in the Contract selection screen. When accepting a Promotion Challenge you will need to defeat a boss in its own boss level that 
          will be a multi-phased boss battle that requires you to have upgraded your stats enough, modified your weapons accordingly and gained enough skills at the 
          combat system to be able to take down this boss. Once this Promotion Challenge has been completed, you will be awarded with a promotion which will unlock 
          new weapons and mods and allow you to upgrade your existing weapons further.
        </p>
    </Article>
    <Article id="status" label="Status">
        <h3>Current Game Status</h3>
        <p>Janitor Defense Group is currently in the pre-production / prototype phase as I work out all the details.</p>
    </Article>
    </>
  )
}

export default JanitorDefenseGroup;