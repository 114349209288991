import React from 'react';
import { Link } from 'react-router-dom';

import Article from '../../components/Article';
import TableOfContents from '../../components/TableOfContents';
import Image from '../../components/Image';
import IconLink from '../../components/IconLink';

import {GitHubIcon, NodeIcon, DownloadIcon} from '../../components/Icons';

import sedPowershell from '../../images/apps/sed/SED_Powershell.png';
import sedSettings from '../../images/apps/sed/SED_Settings.png';

const toc = [
    {"label":"About", "id": "about"},
    {"label":"Setup", "id": "setup"},
    {"label":"How to Use", "id": "howto"}
]

const SteamEasyDownload = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="about" label="About">
        <h2>Steam Easy Download</h2>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
            <IconLink to="https://github.com/batman485/SteamEasyDownload" target="_blank" icon={GitHubIcon} className="flex-none">GitHub</IconLink>
            <IconLink to="https://github.com/batman485/SteamEasyDownload/releases/download/v0.3.0/SteamEasyDownload_win_x64_0.3.0.zip" target="_blank" icon={DownloadIcon} className="flex-none">Latest Release</IconLink>
        </div>
        <Image src={sedPowershell} alt="Steam Easy Download" />
        <h3>Built With</h3>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
            <IconLink to="https://nodejs.org/" target="_blank" icon={NodeIcon} className="flex-none">Node.js</IconLink>
            <IconLink to="https://github.com/vercel/pkg" target="_blank" icon={GitHubIcon} className="flex-none">Pkg</IconLink>
        </div>
        <p>
            We've all had the same problem before, we have a friend with a fleet of computers in their basement for LAN parties but making sure that the 
            appropriate steam games are downloaded and updated on all computers beforehand is a pain. This is where Steam Easy Download can come into play. 
            All you need to do is setup the config file and run Steam Easy Download on each computer and it will make sure the desired games are updated and downloaded. 
            Using Task Scheduler this can be setup to be run on a regular basis to keep each computer up to date.
        </p>
    </Article>
    <Article id="setup" label="Setup">
        <h3>How to Setup Steam Easy Download</h3>
        <Image src={sedSettings} alt="Steam Easy Download Settings" />
        <ol>
            <li>Open the Settings.ini file by right clicking on it and then clicking on Edit</li>
            <li>Under the Steam section:
                <ul>
                    <li>Enter the path to your steam.exe file after exepath=</li>
                    <li>Enter the path to your steamapps folder after apppath=</li>
                    <li>(Optional) Enter your Steam username after user=</li>
                    <li>(Optional) Enter your Steam password after pass=</li>
                </ul>
            </li>
            <li>
                On the appids variable, add a comma seperated list of Steam apps or games you would like to have Steam Easy Downloader download for you. 
                App Ids can be found at <Link to="https://steamdb.info/" target="_blank">https://steamdb.info/</Link>
            </li>
            <li>Save the Settings.ini file with these updates</li>
        </ol>
    </Article>
    <Article id="howto" label="How To">
        <h3>How to Use Steam Easy Download</h3>
        <ol>
            <li>Right click on SteamEasyDownload.exe and then Run as administrator</li>
            <li>Note: If you have Steam Guard enabled, it will prompt you to put in a Steam Guard Code before downloads will begin</li>
        </ol>
    </Article>
    </>
  )
}

export default SteamEasyDownload;