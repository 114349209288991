import React from 'react';
import { Link } from 'react-router-dom';
import Article from '../components/Article';

import AvatarProfessional from '../images/avatars/CCAvatar_Professional_Head.png';
import AvatarGame from '../images/avatars/CCAvatar_Game_Head.png';
import AvatarApp from '../images/avatars/CCAvatar_App_Head.png';


const Welcome = () => {
  return (
    <>
    <Article id="welcome" label="Welcome">
        <h2>Welcome! Here's a <span className="inline-block lg:hidden">bit</span><span className="hidden lg:inline-block cursor-help dark:text-sky-300 text-sky-500" title="0 or 1 is a bit. Here's a BIT about me. Get it? Sorry, that's a bad joke.">1</span> about me</h2>
        <p>
            I am a self-starting fast learner that thrives on tough challenges. Able to speak at both 
            a client facing level and a development level, I regularly act as the conduit between client 
            and developer ensuring that both the client’s goals and objectives are clearly stated and documented 
            while breaking those down in to individual tasks that can be acted upon by a developer.
        </p>
        <p>
            When I'm not coding for work, you can often find me coding for fun at home learning new languages, trying 
            out the latest and greatest technology, or working on my own projects. When I do take a break from my 
            computer, you'll find me doing some Kenpo Karate, playing a few games with friends or going on adventures
            around the world.
        </p>
    </Article>
    <Article id="nav" label="Navigation">
        <h2>See what I've done</h2>
        <ul className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 list-none">
            <li className="group bg-white p-2 drop-shadow-lg border-2 border-transparent">
             <Link to={'/career'} className="no-underline group-hover:underline">
                <img className="aspect-[3/2] w-full object-contain object-top bg-gray-950/50 group-hover:bg-gray-950/75" src={AvatarProfessional} alt="Professional Avatar"/>
                <h2 className="mt-2 font-marker text-center block text-3xl leading-8 text-gray-900 group-hover:underline">Career</h2>
              </Link>
            </li>
            <li className="group bg-white p-2 drop-shadow-lg border-2 border-transparent">
             <Link to={'/game'} className=" no-underline group-hover:underline">
                <img className="aspect-[3/2] w-full object-contain object-top bg-gray-950/50 group-hover:bg-gray-950/75" src={AvatarGame} alt="GameDev Avatar"/>
                <h2 className="mt-2 font-marker text-center text-3xl leading-8 tracking-tight text-gray-900 group-hover:underline">Game Dev</h2>
              </Link>
            </li>
            <li className="group bg-white p-2 drop-shadow-lg border-2 border-transparent">
             <Link to={'/app'} className="no-underline">
                <img className="aspect-[3/2] w-full object-contain object-top bg-gray-950/50 group-hover:bg-gray-950/75" src={AvatarApp} alt="AppDev Avatar"/>
                <h2 className="mt-2 font-marker text-center text-3xl leading-8 tracking-tight text-gray-900 group-hover:underline">App Dev</h2>
              </Link>
            </li>
        </ul>
    </Article>
    </>
  )
}

export default Welcome;