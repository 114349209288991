import React from 'react';
import { Link } from 'react-router-dom';

import Article from '../components/Article';
import TableOfContents from '../components/TableOfContents';
import Image from '../components/Image';
import IconLink from '../components/IconLink';
import {PDFIcon, ReadMoreIcon, ItchIcon} from '../components/Icons';

import jdgStart from '../images/games/jdg/JDG_Start.png';
import wlbLogo from '../images/games/wlb/WLB_Logo.png';
import looLogo from '../images/games/loo/LOO_Logo.png';

const toc = [
  {"label":"Last One Out", "id": "lastoneout"},
  {"label":"Janitor Defense Group", "id": "janitordefensegroup"},
  {"label":"Win, Lose, or Banana", "id": "winlosebanana"}
]

const GameDev = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="lastoneout" label="Last One Out">
        <Link to={"lastoneout"}><h2>Last One Out</h2></Link>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://batman485.itch.io/lastoneout" target="_blank" icon={ItchIcon} className="flex-none">Download on Itch.io</IconLink>
          <IconLink to="https://itch.io/jam/vrca-jam" target="_blank" icon={ItchIcon} className="flex-none">VRCA Jam - May 2024</IconLink>
        </div>
        <Image src={looLogo} alt="Last One Out" />
        <p>
          The space station is going down but you left your AI K.A.R.E.N. on do not disturb while you were napping. When you finally wake up you've only got 20 minutes left to get 
          to the exit. It looks like you'll be the last one out.
        </p>
        <p>Last one out is a VR space station walking simulator with gravity problems. It was created for the game jam <Link to="https://itch.io/jam/vrca-jam">VRCA Jam - May 2024</Link>.</p>
        <br/>
        <IconLink to="https://batman485.itch.io/lastoneout" target="_blank" icon={ItchIcon} className="flex-none">Details About Last One Out on Ithch.io</IconLink>
        <IconLink to="https://batman485.itch.io/lastoneout/devlog" target="_blank" icon={ReadMoreIcon} className="flex-none">Devlog for Last One Out</IconLink>
    </Article>
    <Article id="janitordefensegroup" label="Janitor Defense Group">
        <Link to={"janitordefensegroup"}><h2>Janitor Defense Group</h2></Link>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://www.dropbox.com/scl/fi/xztf5nwkreda3seifxbgt/JanitorDefenseGroup_GDD.pdf?rlkey=lmgg0gwysxcu7xb8z6jndqcib&dl=1" target="_blank" icon={PDFIcon} className="flex-none">GDD</IconLink>
          <IconLink to="https://www.dropbox.com/scl/fi/9md3cb2aaozqhvd502dph/JanitorDefenseGroup_PitchDeck.pdf?rlkey=lkzbq6znym0stz99rcsw9s3n1&dl=1" target="_blank" icon={PDFIcon} className="flex-none">Pitch Deck</IconLink>
        </div>
        <Image src={jdgStart} alt="Janitor Defense Group" />
        <p>
          As a member of the <Link to={"janitordefensegroup"}><b>Janitor Defense Group</b></Link>, you will be tasked with the cleanup of the entities that form from 
          negative energy built up during the day in office buildings. Using upgradeable and moddable cleaning supplies, 
          such as brooms, mops and disinfectants, go toe-to-toe with entities to earn work experience and get paid for your hard work.
        </p>
        <h3>TLDR;</h3>
        <p>Choose contract &gt; Cleanup entities &gt; Upgrade stats, weapons &gt; Repeat until promoted to the top</p>
        <br/>
        <IconLink to="janitordefensegroup" target="_self" icon={ReadMoreIcon} className="flex-none">Read More about Janitor Defense Group</IconLink>
    </Article>
    <Article id="winlosebanana" label="Win, Lose, or Banana">
      <Link to={"winlosebanana"}><h2>Win, Lose, or Banana</h2></Link>
        <Image src={wlbLogo} alt="Win, Lose, or Banana" />
        <p>
          <b>Win, Lose or Banana</b> (also known as 'Who's the Banana?', 'Are you a Banana?' or 'The Best Game Ever') is a quick and light deception game where a winner 
          tries to get his prize, a banana.
        </p>
        <p>
          Win, Lose, or Banana was created by <Link to={'https://winloseorbanana.com/'} target="_blank">Asmadi Games</Link> as a simple 3 card game. In an attempt to learn 
          how to develop networked multiplayer games (and having destroyed many paper copies of this game since it goes everywhere with me), I recreated it in digital 
          form in all it's glory.
        </p>
        <br/>
        <IconLink to="winlosebanana" target="_self" icon={ReadMoreIcon} className="flex-none">Read More about Win, Lose or Banana</IconLink>
    </Article>
    </>
  )
}

export default GameDev;