import {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import AvatarWelcome from '../images/avatars/CCAvatar_Welcome.png';
import AvatarProfessional from '../images/avatars/CCAvatar_Professional.png';
import AvatarGame from '../images/avatars/CCAvatar_Game.png';
import AvatarApp from '../images/avatars/CCAvatar_App.png';

const Avatar = () => {
    const location = useLocation();

    const createAvatar = () => {
        let avatar = {src: AvatarWelcome, alt: 'Welcome Avatar'};

        switch (location.pathname.split("/")[1]) {
            case 'career':
                avatar = {src: AvatarProfessional, alt: 'Professional Avatar'};
                break;
            case 'game':
                avatar = {src: AvatarGame, alt: 'GameDev Avatar'};
                break;
            case 'app':
                avatar = {src: AvatarApp, alt: 'AppDev Avatar'};
                break;
            default:
                break;
        }

        return <img className="avatar hidden lg:inline-block" src={avatar.src} alt={avatar.alt} />;
    }

    const preloadImages = () => {
        const images = [AvatarWelcome, AvatarProfessional, AvatarGame, AvatarApp];
        images.forEach(image => {
            new Image().src = image;
        });
    }

    useEffect(preloadImages, []);

    return (
        <>{createAvatar()}</>
    )
}

export default Avatar;