import React from 'react';
import Article from '../components/Article';
import TableOfContents from '../components/TableOfContents';
import Qualification from '../components/Qualification';
import IconLink from '../components/IconLink';
import {PDFIcon} from '../components/Icons';

import qualifications from '../data/qualifications';

const toc = [
  {"label":"Professional Summary", "id": "summary"},
  {"label":"Core Qualifications", "id": "qualifications"},
  {"label":"Experience", "id": "experience"},
  {"label":"Education", "id": "education"},
  {"label":"Certifications and Achievements", "id": "certs"}
]
const tocExperience = [
  {"label":"2015 - Current | Celerity Information Services Ltd.", "id": "celerity"},
  {"label":"2011 - 2015 | KnowledgeVision Systems Inc.", "id": "kvsi"},
  {"label":"2008 - 2011 | Qvidian Corporation", "id": "qvidian"},
  {"label":"2010 - 2019 | Manchester Karate Studio - Volunteer", "id": "mks"}
]

const Career = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="docs">
      <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
        <IconLink to="https://www.dropbox.com/scl/fi/1gznynu8wcyo74ynkcsft/ChristianCarmanResume.pdf?rlkey=4xfvif27mfp6g5d9iw271rl40&dl=1" target="_blank" icon={PDFIcon} className="flex-none text-lg">Download Resume as PDF</IconLink>
      </div>
    </Article>
    <Article id="summary" label="Professional Summary">
        <h2>Professional Summary</h2>
        <p>
          Results-driven leader and seasoned technologist with a passion for tackling complex challenges. Possesses exceptional communication 
          skills, able to effectively bridge the gap between clients and developers, ensuring seamless understanding and alignment of goals 
          and objectives. Skilled in breaking down high-level objectives into actionable tasks, driving projects forward, and delivering 
          innovative solutions that exceed customer expectations.
        </p>
        <p>
          Adept at managing multiple clients simultaneously, leveraging cutting-edge technologies and industry best practices to design and 
          develop tailored solutions. Committed to mentoring and developing team members, providing constructive feedback, and fostering a 
          culture of collaboration and success. Proven expertise in customer onboarding, troubleshooting, and issue resolution, with a strong 
          ability to work cross-functionally to drive customer success.
        </p>
    </Article>
    <Article id="qualifications" label="Core Qualifications">
        <h2>Core Qualifications</h2>
        <ul className="p-0">
          {qualifications.map((qualification) => <Qualification category={qualification.category} list={qualification.list} />)}
        </ul>
    </Article>
    <Article id="experience" label="Experience">
        <h2>Experience</h2>
        <TableOfContents links={tocExperience} />
        <h3 id="celerity" className="dark:text-sky-300 text-sky-500 uppercase">Celerity Information Services Ltd.</h3>
        <h4 className="p-0 m-0">
          2019 - Current | VP of Engineering<br/>
          2015 - 2019 | Head of System Integration<br/>
          2015 | Sr. System Integration Consultant
        </h4>
        <p>
          Celerity-IS is an award-winning marketing automation, database and CRM consulting agency specializing in the Adobe Marketing Suite, delivering 
          solutions in countries around the world. At Celerity-IS, I:
        </p>
        <ul className="m-0 dark:marker:text-sky-300 marker:text-sky-500">
          <li className="p-0 m-0"><p>Lead the engineering team, driving growth and direction in alignment with business needs, and mentor technical resources to exceed expectations.</p></li>
          <li className="p-0 m-0"><p>Manage global teams of client-facing engineers, delivering integrations, extensions, and tools for Adobe Marketing Suite, and oversee relationships with shared developer resources across multiple countries.</p></li>
          <li className="p-0 m-0"><p>Implemented process automation and streamlined client relationships, increasing client satisfaction through the adoption of Jira, Confluence, Bitbucket, Zendesk, and internal development environments, and trained teams on best practices.</p></li>
          <li className="p-0 m-0"><p>Reorganized engineering teams, aligning job titles and descriptions with industry standards, and created a new Product Development department, generating a secondary income stream through re-sellable products that saved clients millions of dollars in fraud and improved marketing automation.</p></li>
          <li className="p-0 m-0"><p>Developed multiple enterprise-level integrations with external platforms, including POS integrations, mobile applications, and custom marketing integrations, demonstrating expertise in Adobe Marketing Suite and driving business success.</p></li>
        </ul>

        <h3 id="kvsi" className="dark:text-sky-300 text-sky-500 uppercase">KnowledgeVision Systems Inc.</h3>
        <h4 className="p-0 m-0">
          2014 - 2015 | Manager, Client Services<br/>
          2011 - 2014 | Sr. Client Services Specialist
        </h4>
        <p>
          KnowledgeVision Systems Inc. (KVSI) is a software & professional services SaaS company that develops, sells and implements online interactive presentation 
          applications for many types of businesses. While at KVSI, I:
        </p>
        <ul className="m-0 dark:marker:text-sky-300 marker:text-sky-500">
          <li className="p-0 m-0"><p>Led a team of support engineers, resolving customer issues through Zendesk, and coached team members to ensure effective problem-solving.</p></li>
          <li className="p-0 m-0"><p>Contributed to the Product Development Advisory Team, collaborating with developers to identify, prioritize, and create new product features and bug fixes, driving product evolution to meet client needs.</p></li>
          <li className="p-0 m-0"><p>Provided pre-sales support, including technical demos, IT team collaboration, and in-depth product expertise, helping to close deals and drive revenue growth.</p></li>
          <li className="p-0 m-0"><p>Successfully trained global CS team members on product support, Zendesk, HTML5/CSS, and Jira, ensuring consistency and excellence in customer support.</p></li>
          <li className="p-0 m-0"><p>Developed custom integrations and dashboards between Salesforce, Zendesk, and Harvest, providing real-time customer insights and driving business efficiency, and created innovative solutions like the Event Registration system and Certification Generator, generating significant revenue and enhancing customer value.</p></li>
        </ul>

        <h3 id="qvidian" className="dark:text-sky-300 text-sky-500 uppercase">Qvidian Corporation</h3>
        <h4 className="p-0 m-0">
          2010 - 2011 | Customer Support Specialist<br/>
          2008 - 2010 | Implementation Specialist<br/>
          2008 | Client Management Intern
        </h4>
        <p>
          Qvidian is a software & professional services SaaS company that develops, sells and implements Sales Enablement, Playbook & Sales Analytics solutions for 
          Salesforce & Oracle OnDemand. While at Qvidian, I:
        </p>
        <ul className="m-0 dark:marker:text-sky-300 marker:text-sky-500">
          <li className="p-0 m-0"><p>Resolved technical issues with Qvidian's software solutions, including inciteKnowledge, Knowledge Manager, and Sant Suite, utilizing Techexcel and Salesforce Cases.</p></li>
          <li className="p-0 m-0"><p>Provided Gold Support to top-tier customers, offering direct access and priority resolution for critical bugs and enhancements, and collaborated with developers to ensure timely solutions.</p></li>
          <li className="p-0 m-0"><p>Led software conversions, leveraging expertise in Qvidian's software packages, Microsoft Office, SQL, and technical troubleshooting, to ensure seamless transitions for customers.</p></li>
          <li className="p-0 m-0"><p>Successfully implemented customer onboarding, creating accounts, building Word Documents and PowerPoint presentations, and training customers, in collaboration with Project Managers.</p></li>
          <li className="p-0 m-0"><p>Developed a Word VBA macro to resolve a recurring bug, distributing it to all customers, and demonstrating technical expertise and problem-solving skills.</p></li>
        </ul>

        <h3 id="mks" className="dark:text-sky-300 text-sky-500 uppercase">Manchester Karate Studio</h3>
        <h4 className="p-0 m-0">2010 - 2019 | Leadership Team [Volunteer]</h4>
        <p>
          Manchester Karate Studio is a Kenpo Karate studio formed in 1984 by Steven White who is currently an 9th degree Black Belt and has been training in martial 
          arts for over 40 years. While at MKS, I:
        </p>
        <ul className="m-0 dark:marker:text-sky-300 marker:text-sky-500">
          <li className="p-0 m-0"><p>Taught Kenpo Karate to students of various skill levels, fostering a safe and controlled environment, and utilizing effective communication, patience, and motivation to drive student success.</p></li>
          <li className="p-0 m-0"><p>Successfully mentored students from White Belt to Black Belt, demonstrating long-term commitment and dedication to their growth and development.</p></li>
          <li className="p-0 m-0"><p>Led advanced classes, including Class 302, through rigorous training programs, such as the 15-week Black Belt test, and provided individualized support and guidance to ensure students met the highest standards.</p></li>
          <li className="p-0 m-0"><p>Performed martial arts demonstrations at events and ceremonies, showcasing expertise in Kenpo Techniques, Filipino Stick and Knife Fighting, and Gracie Jui-Jitsu.</p></li>
          <li className="p-0 m-0"><p>Developed strong leadership and motivational skills, inspiring students to push beyond their limits and achieve their goals, and applying these skills to other areas of life and leadership.</p></li>
        </ul>
    </Article>
    <Article id="education" label="Education">
        <h2>Education</h2>
        <h3 id="snhu" className="dark:text-sky-300 text-sky-500 uppercase">Southern New Hampshire University</h3>
        <h4 className="p-0 m-0">2004 - 2008 | Bachelors of Business Administration</h4>
        <ul className="m-0 dark:marker:text-sky-300 marker:text-sky-500">
          <li className="p-0 m-0"><p>Focused on Administration and Organizational Leadership.</p></li>
          <li className="p-0 m-0"><p>Achieved Dean's List while balancing academic responsibilities with extracurricular activities and part-time work.</p></li>
        </ul>
    </Article>
    <Article id="certs" label="Certifications">
        <h2>Certifications and Achievements</h2>
        <ul className="m-0 dark:marker:text-sky-300 marker:text-sky-500">
          <li className="p-0 m-0"><p>Adobe Campaign Business Practitioner Certification</p></li>
          <li className="p-0 m-0"><p>Adobe Campaign Developer Certification</p></li>
          <li className="p-0 m-0"><p>Celerity GEM Award Winner</p></li>
          <li className="p-0 m-0"><p>3rd Degree Black Belt in Kenpo Karate</p></li>
          <li className="p-0 m-0"><p>Blue Belt in Gracie Ju-Jitsu</p></li>
          <li className="p-0 m-0"><p>Dean’s List at SNHU</p></li>
        </ul>
    </Article>
    </>
  )
}

export default Career;