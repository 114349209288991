import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const pages = [
    { name: 'Career', href: '/career' },
    { name: 'Game Dev', href: '/game' },
    { name: 'App Dev', href: '/app' },
]

const Nav = (props) => {
    const location = useLocation();

    return (
        <div className="sticky z-40 top-0 mx-auto max-w-7xl px-6 lg:flex lg:px-8">
            <div className="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                <div className="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography" data-mdx-content="true">
                <nav className="flex bg-white dark:bg-gray-950 justify-start pb-2" aria-label="Breadcrumb">
                    <ol className="mx-auto flex w-full max-w-screen-xl p-0 -ml-4">
                        <li className="flex">
                        <div className="flex items-center">
                            <Link to="/" className="text-gray-600 hover:text-gray-500">
                            <svg className="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z" clipRule="evenodd" />
                            </svg>
                            <span className="sr-only">Home</span>
                            </Link>
                        </div>
                        </li>
                        {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                            <svg className="h-5 w-5 flex-shrink-0 text-gray-600 dark:text-white" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            {page.href === location.pathname ?
                            <Link to={page.href} className="ml-4 text-sm font-medium hover:text-gray-500 dark:text-sky-300 text-sky-500" aria-current="page" onClick={props.goToTop}>{page.name}</Link> :
                            <Link to={page.href} className="ml-4 text-sm font-medium hover:text-gray-500 text-gray-600 dark:text-white">{page.name}</Link>
                            }
                            </div>
                        </li>
                        ))}
                    </ol>
                </nav>
                </div>
            </div>
        </div>
    )
}

export default Nav