'use client'
import { useEffect, useState } from 'react';
import { ArrowUpIcon } from './Icons';


const ScrollToTop = (props) => {
    let [mounted, setMounted] = useState(false);
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted || !showTopBtn) return null;

    return (
        <button
            type="button"
            className="group fixed right-4 bottom-4 z-50 -m-2.5 p-2.5"
            onClick={() => props.goToTop()}
        >
            <span className="sr-only">Back To Top</span>
            <ArrowUpIcon className="h-6 w-6 fill-white opacity-50 transition-opacity group-hover:opacity-100 lg:fill-gray-900 lg:dark:fill-white" />
        </button>
    )
}

export default ScrollToTop;