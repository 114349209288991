import React from 'react';
import { Link } from 'react-router-dom';

import Article from '../../components/Article';
import TableOfContents from '../../components/TableOfContents';
import Image from '../../components/Image';
import IconLink from '../../components/IconLink';

import {GitHubIcon, ElectronJSIcon, TailwindIcon, DownloadIcon} from '../../components/Icons';

import ghMain from '../../images/apps/gh/GH_MainScreen.png';
import ghReleaseList from '../../images/apps/gh/GH_ReleaseList.png';
import ghInstall from '../../images/apps/gh/GH_InstallRelease.png';
import ghLaunch from '../../images/apps/gh/GH_LaunchRelease.png';
import ghUninstall from '../../images/apps/gh/GH_Uninstall.png';

const toc = [
    {"label":"About", "id": "about"},
    {"label":"How to Use", "id": "howto"},
]

const GodotHub = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="about" label="About">
        <h2>GodotHub</h2>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://github.com/batman485/GodotHub" target="_blank" icon={GitHubIcon} className="flex-none">GitHub</IconLink>
          <IconLink to="https://github.com/batman485/GodotHub/releases/download/v0.1.2/Godot.Hub-0.1.2.Setup.exe" target="_blank" icon={DownloadIcon} className="flex-none">Latest Release</IconLink>
        </div>
        <Image src={ghMain} alt="GodotHub" />
        <h3>Built With</h3>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://www.electronjs.org/" target="_blank" icon={ElectronJSIcon} className="flex-none">Electron JS</IconLink>
          <IconLink to="https://www.electronforge.io/" target="_blank" icon={ElectronJSIcon} className="flex-none">Electron Forge</IconLink>
          <IconLink to="https://www.electronforge.io/" target="_blank" icon={TailwindIcon} className="flex-none">Tailwind CSS</IconLink>
        </div>
        <p>
          Do you like to test out the latest and greatest version of Godot while still working in your existing version? Do you collaborate with people 
          across multiple Godot projects all using different versions of Godot? I feel your pain. That’s why I made GodotHub. It gives you a simple user 
          interface to download, manage and launch as many different versions of Godot as you would like in one easy to use place. No more juggling different 
          folders or app files, updating shortcuts, or even digging through the Godot GitHub repository when you need a specific version. Say goodbye to that 
          hassle and hello to GodotHub.
        </p>
    </Article>
    <Article id="howto" label="How to Use">
        <h3>How To Use GodotHub</h3>
        <h4>1. Install a Release</h4>
        <p>To get started you will need to install at least one release. To do this:
          <ol>
            <li>Click on the Install Release button in the top right corner.</li>
            <li>In the list of releases, click on Install Release on the right of the release version you would like to install.<Image src={ghReleaseList} alt="Install Release" /></li>
            <li>
                You will then be prompted to choose which version to install (Godot Standard for GDScript or Godot Mono (.Net) for C#), which architecture you are running, and the location 
                where you want to install the release to.<Image src={ghInstall} alt="Install Options" />
            </li>
            <li>Click on Install to download and install the selected release.</li>
          </ol>
        </p>
        <h4>2. Launch a Release</h4>
        <p>To launch a release, click on the Launch button on the right of the release you would like to launch.</p>
        <Image src={ghLaunch} alt="Launch Release" />
        <h4>3. Uninstall a Release</h4>
        <p>To unsinstall a release, click on the options button to the right of the Launch button (the 3 dots) of the release you would like to uninstall and then click on Uninstall in the dropdown menu.</p>
        <Image src={ghUninstall} alt="Uninstall Release" />
    </Article>
    </>
  )
}

export default GodotHub;