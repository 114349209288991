'use client'
import { useEffect, useState } from 'react';

function ThemeIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-5-8a5 5 0 0 0 5 5V7a5 5 0 0 0-5 5Z"
      />
    </svg>
  )
}

const ThemeToggle = (props) => {
    let [mounted, setMounted] = useState(false);
    let otherTheme = props.currentTheme === 'dark' ? 'light' : 'dark';

    useEffect(() => {
        setMounted(true)
    }, [])

    if (!mounted) return null;

    return (
        <button
            type="button"
            className="group fixed right-4 top-4 z-50 -m-2.5 p-2.5"
            onClick={() => props.toggleTheme(otherTheme)}
            title={`Switch to ${otherTheme} theme`}
        >
            <span className="sr-only">Switch to {otherTheme} theme</span>
            <ThemeIcon className="h-6 w-6 fill-white opacity-50 transition-opacity group-hover:opacity-100 lg:fill-gray-900 lg:dark:fill-white" />
        </button>
    )
}

export default ThemeToggle