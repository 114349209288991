import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import FixedSidebar from './components/FixedSidebar';
import ThemeToggle from './components/ThemeToggle';
import { Intro, IntroFooter } from './components/Intro';
import AnimatedRoutes from './pages/AnimatedRoutes';

function App() {
  let [currentTheme, setCurrentTheme] = useState('dark');
  
  useEffect(() => {
    if(currentTheme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [currentTheme]);

  return (
    <div className="main h-full min-h-screen bg-white dark:bg-gray-950 flex flex-col" role="main">
      <Router>
        <FixedSidebar main={<Intro />} footer={<IntroFooter />} />
        <ThemeToggle currentTheme={currentTheme} toggleTheme={(newTheme) => setCurrentTheme(newTheme)} />
        <div className="relative flex-auto">
          <AnimatedRoutes />
        </div>
      </Router>
    </div>
  );
}

export default App;