import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import {FormattedDate} from './FormattedDate';
import ContentWrapper from './ContentWrapper';

const ArticleHeader = ({ id, label, date }) => {
  const location = useLocation();

  return (
    <header className="relative xl:mb-0">
      <div className="pointer-events-none absolute left-[max(-0.5rem,calc(50%-18.625rem))] top-0 z-50 flex h-4 items-center justify-end gap-x-2 lg:left-0 lg:right-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] xl:h-8">
        <HashLink to={`${location.pathname}#${id}`} className="inline-flex">
          {
          label ?  
            <span className="hidden xl:pointer-events-auto xl:block xl:text-2xs/4 xl:font-medium xl:text-white/50">{label}</span> :
          date ? 
            <FormattedDate
              date={date}
              className="hidden xl:pointer-events-auto xl:block xl:text-2xs/4 xl:font-medium xl:text-white/50"
            />
          : ''}
        </HashLink>
        <div className="h-[0.0625rem] w-3.5 bg-gray-400 lg:-mr-3.5 xl:mr-0 xl:bg-gray-300" />
      </div>
      <ContentWrapper>
        <div className="flex">
          <HashLink to={`${location.pathname}#${id}`} className="inline-flex">
          {
          label ?  
            <span className="hidden">{label}</span> :
          date ? 
            <FormattedDate
              date={date}
              className="hidden xl:pointer-events-auto xl:block xl:text-2xs/4 xl:font-medium xl:text-white/50"
            />
          : ''}
          </HashLink>
        </div>
      </ContentWrapper>
    </header>
  )
}

export default ArticleHeader