import React from 'react';
import { HashLink } from 'react-router-hash-link';

const TableOfContents = (props) => {
  return (
    <ul className="m-0 p-0 pl-1 list-none">
        {props.links.map((link) => <li key={link.id} className="p-0 m-0 text-lg"><HashLink smooth to={`#${link.id}`}>{link.label}</HashLink></li>)}
    </ul>
  )
}

export default TableOfContents;