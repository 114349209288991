import { useEffect, useRef, useState } from 'react';

import ArticleHeader from './ArticleHeader';
import ContentWrapper from './ContentWrapper';


const Article = ({ id, label, date, children }) => {
    let heightRef = useRef(null)
    let [heightAdjustment, setHeightAdjustment] = useState(0)

    useEffect(() => {
        if (!heightRef.current) return;

        let observer = new window.ResizeObserver(() => {
            if (!heightRef.current) return;

            let { height } = heightRef.current.getBoundingClientRect()
            let nextMultipleOf8 = 8 * Math.ceil(height / 8)
            setHeightAdjustment(nextMultipleOf8 - height)
        });

        observer.observe(heightRef.current)

        return () => {observer.disconnect()}
    }, []);

  return (
    <article
      id={id}
      className="scroll-mt-16 mb-6"
      style={{ paddingBottom: `${heightAdjustment}px` }}
    >
      <div ref={heightRef}>
        {label || date ? <ArticleHeader id={id} label={label} date={date} /> : ''}
        <ContentWrapper className="typography" data-mdx-content>
          {children}
        </ContentWrapper>
      </div>
    </article>
  )
}

export default Article;