import React from 'react';
import { Link } from 'react-router-dom';

import Article from '../../components/Article';
import TableOfContents from '../../components/TableOfContents';
import Image from '../../components/Image';

import wlbLogo from '../../images/games/wlb/WLB_Logo.png';
import wlbScreenshotInGame from '../../images/games/wlb/WLB_Screenshot_InGame.png';

const toc = [
    {"label":"About", "id": "about"},
    {"label":"How to Play", "id": "howto"},
    {"label":"Gameplay Summary", "id": "summary"},
    {"label":"Status", "id": "status"},
  ]

const WinLoseBanana = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="about" label="About">
        <h2>Win, Lose, or Banana</h2>
        <Image src={wlbLogo} alt="Win, Lose, or Banana" />
        <p>
          <b>Win, Lose or Banana</b> (also known as 'Who's the Banana?', 'Are you a Banana?' or 'The Best Game Ever') is a quick and light deception game where a winner 
          tries to get his prize, a banana.
        </p>
        <p>
          Win, Lose, or Banana was created by <Link to={'https://winloseorbanana.com/'} target="_blank">Asmadi Games</Link> as a simple 3 card game. In an attempt to learn 
          how to develop networked multiplayer games (and having destroyed many paper copies of this game since it goes everywhere with me), I recreated it in digital 
          form in all it's glory.
        </p>
    </Article>
    <Article id="howto" label="How to Play">
    <Image src={wlbScreenshotInGame} alt="Gameplay" />
        <h3>How to Play</h3>
        <p>
          The rules are simple. A deck of cards is shuffled together and each player is dealt one card. One of you has won! The <b>winner</b> will be made known to everybody 
          but feel free to shout it out loud, victorious and happy. As the winner, she is of course entitled to a prize. A <b>banana</b>. The <b>loser</b>, unfortunately, is 
          displeased. He wanted to win! Or at least be the sought-after prize. Blinded by jealousy, he must do the unthinkable: <b>Pretend to be the banana</b>. 
        </p>
        <p>
          Once the winner is revealed, the other two players must attempt to convince her that they are in fact the true and real banana. Everything is fair game, except of course for a card 
          reveal. Promises, deals, sale of firstborn... anything goes. When the winner makes her decision, she selects one of the players by touching the name of the player and 
          touching the Choose Player button. If correct, both she and the banana are winners. Hugs, elation, celebration should result. If wrong, the loser's terrible plan has 
          seen fruition, and he should cackle madly with victorious glee.
        </p>
    </Article>
    <Article id="summary" label="Summary">
        <h3>Gameplay Summary</h3>
        <p>At the start of the round, a deck of cards will be shuffled together and dealt out to each player. The deck consists of:</p>
        <ul className="text-base list-none">
          <li className="p-0 m-0 mt-5">2 Players: 1 win, 1 lose, 1 banana</li>
          <li className="p-0 m-0">3 Players: 1 win, 1 lose, 1 banana</li>
          <li className="p-0 m-0">4 Players: 1 win, 2 lose, 1 banana</li>
          <li className="p-0 m-0">5 Players: 1 win, 2 lose, 2 banana</li>
        </ul>
        <p>Once each player has a card, they will need to take some very important and very, very serious actions to try and win. These actions are:</p>
        <p>
          <b>Winner:</b> Choose a banana player<br />
          <span className="pl-5">Win Condition: You chose the banana player!</span><br />
          <span className="pl-5">Lose Condition: You chose the loser player...</span>
        </p>
        <p>
          <b>Banana:</b> Convince the Winner you have the banana (because you do)<br />
          <span className="pl-5">Win Condition: Winner chose you!</span><br />
          <span className="pl-5">Lose Condition: Winner didn't choose you...</span>
        </p>
        <p>
          <b>Loser:</b> Convince the Winner you have the banana (because you do, right?)<br />
          <span className="pl-5">Win Condition: Winner chose you!</span><br />
          <span className="pl-5">Lose Condition: Winner didn't choose you...</span>
        </p>
    </Article>
    <Article id="status" label="Status">
        <h3>Current Game Status</h3>
        <p>Completed but since I do not own the rights to this game you'll just have to wait until we're in person and we can play together.</p>
    </Article>
    </>
  )
}

export default WinLoseBanana;