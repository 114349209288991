import { Link } from 'react-router-dom';
import { LinkedInIcon, GitHubIcon, EmailIcon } from './Icons';
import IconLink from './IconLink';

import info from '../data/info';

export function Intro() {
  return (
    <>
      <div>
        <Link to={"/"} className="no-underline">
          <h1 className="mt-14 font-display text-4xl/tight font-light text-white text-center lg:text-left">
            <span className="inline lg:block">{info.fName}&nbsp;</span>
            <span className="inline lg:block text-sky-300">{info.lName}</span>
          </h1>
        </Link>
      </div>
      <p className="mt-4 text-m/6 text-gray-300">
        An ever curios developer on the hunt to understand how things work and share that knowledge with 
        everyone. Also a 3rd degree black belt so be careful when approaching.
      </p>
      <div className="mt-4 flex flex-wrap justify-center gap-x-1 gap-y-3 sm:gap-x-2 lg:justify-start">
        <IconLink to={info.linkedIn} target="_blank" icon={LinkedInIcon} className="flex-none">LinkedIn</IconLink>
        <IconLink to={info.github} icon={GitHubIcon} className="flex-none">GitHub</IconLink>
      </div>
    </>
  )
}

export function IntroFooter() {
  return (
    <p className="flex items-baseline gap-x-2 text-[0.8125rem]/6 text-gray-500">
      Feel free to reach out
      <IconLink to={`mailto:${info.email.contact}`} icon={EmailIcon} className="flex-none">Email</IconLink>
    </p>
  )
}