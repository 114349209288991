import React from 'react';
import { Link } from 'react-router-dom';

import Article from '../components/Article';
import TableOfContents from '../components/TableOfContents';
import Image from '../components/Image';
import IconLink from '../components/IconLink';

import {LinkIcon, GitHubIcon, ReactIcon, NodeIcon, FirebaseIcon, ElectronJSIcon, TailwindIcon, DownloadIcon, ReadMoreIcon} from '../components/Icons';

import sdmHome from '../images/apps/sdm/SDM_HomeScreen.png';
import ghMain from '../images/apps/gh/GH_MainScreen.png';
import sedPowershell from '../images/apps/sed/SED_Powershell.png';

const toc = [
  {"label":"GodotHub", "id": "godothub"},
  {"label":"Social Distancing Movies", "id": "sdmovies"},
  {"label":"Steam Easy Download", "id": "steameasydownload"}
]

const AppDev = () => {
  return (
    <>
    <Article id="toc" label="Table of Contents">
        <h2>Table of Contents</h2>
        <TableOfContents links={toc} />
    </Article>
    <Article id="godothub" label="GodotHub">
        <Link to={"godothub"}><h2>GodotHub</h2></Link>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://github.com/batman485/GodotHub" target="_blank" icon={GitHubIcon} className="flex-none">GitHub</IconLink>
          <IconLink to="https://github.com/batman485/GodotHub/releases/download/v0.1.2/Godot.Hub-0.1.2.Setup.exe" target="_blank" icon={DownloadIcon} className="flex-none">Latest Release</IconLink>
        </div>
        <Image src={ghMain} alt="GodotHub" />
        <h3>Built With</h3>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://www.electronjs.org/" target="_blank" icon={ElectronJSIcon} className="flex-none">Electron JS</IconLink>
          <IconLink to="https://www.electronforge.io/" target="_blank" icon={ElectronJSIcon} className="flex-none">Electron Forge</IconLink>
          <IconLink to="https://www.electronforge.io/" target="_blank" icon={TailwindIcon} className="flex-none">Tailwind CSS</IconLink>
        </div>
        <p>
          Do you like to test out the latest and greatest version of Godot while still working in your existing version? Do you collaborate with people 
          across multiple Godot projects all using different versions of Godot? I feel your pain. That’s why I made GodotHub. It gives you a simple user 
          interface to download, manage and launch as many different versions of Godot as you would like in one easy to use place. No more juggling different 
          folders or app files, updating shortcuts, or even digging through the Godot GitHub repository when you need a specific version. Say goodbye to that 
          hassle and hello to GodotHub.
        </p>
        <h3>How To Use GodotHub</h3>
        <h4>1. Create a Room</h4>
        <p>
          The first thing you will need to do is create a new room for everybody to join. To do this, click on the <Link to="https://sdmovies.cx3tech.com/create">Create a Room</Link> button 
          on the home page or in the top right corner. This will bring you to the Create a Room page. Give the room a name, <i className="text-slate-400">I like to use my favorite quote from the movie</i>, and then enter the 
          name of the movie that you will be watching.
        </p>
    </Article>
    <Article id="sdmovies" label="Social Distancing Movies">
        <Link to={"sdmovies"}><h2>Social Distancing Movies</h2></Link>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://sdmovies.cx3tech.com/" target="_blank" icon={LinkIcon} className="flex-none">View Website</IconLink>
        </div>
        <Image src={sdmHome} alt="Social Distancing Movies" />
        <h3>Built With</h3>
        <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://react.dev/" target="_blank" icon={ReactIcon} className="flex-none">React</IconLink>
          <IconLink to="https://firebase.google.com/" target="_blank" icon={FirebaseIcon} className="flex-none">Firebase</IconLink>
        </div>
        <p>
          Towards the end of 2019 / beginning of 2020 I was no longer able to spend time with my friends in person for… reasons. To stay in touch, 
          we decided to have a weekly movie night online. There were a few ways we could watch movies together on the mainstream streaming platforms 
          or doing a countdown over voice chat and we would all hit play at the same time. The problem is we kept running into an issue where if 
          somebody would have internet issues it would put us out of synch which makes it hard to heckle the movie or laugh together. To solve this 
          problem, I decided to create Social Distancing Movies (or SD Movies for short).
        </p>
        <p>
          With SD Movies, you are able to create a room for all of your friends to join. This room will have the movie file associated to it which 
          everybody who joins can download which makes the movie available offline. Once it’s movie time, select the downloaded move file and hit play. 
          Anybody who is in the room will also start playing at the same time. Since the movie is a downloaded file instead of streaming there are 
          no issues with buffering at all. It uses the bare minimum web calls by just updating everybody when there is a change to the video playing 
          state along with a timestamp of when it started playing so if somebody joins late and the movie is already playing it will just start them 
          up exactly where everybody else is.
        </p>
        <br />
        <IconLink to="sdmovies" target="_self" icon={ReadMoreIcon} className="flex-none">Read More about Social Distancing Movies</IconLink>
    </Article>
    <Article id="steameasydownload" label="Steam Easy Download">
      <Link to={"steameasydownload"}><h2>Steam Easy Download</h2></Link>
      <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
          <IconLink to="https://github.com/batman485/SteamEasyDownload" target="_blank" icon={GitHubIcon} className="flex-none">GitHub</IconLink>
          <IconLink to="https://github.com/batman485/SteamEasyDownload/releases/download/v0.3.0/SteamEasyDownload_win_x64_0.3.0.zip" target="_blank" icon={DownloadIcon} className="flex-none">Latest Release</IconLink>
      </div>
      <Image src={sedPowershell} alt="Steam Easy Download" />
      <h3>Built With</h3>
      <div className="mt-4 flex flex-wrap justify-start gap-x-1 gap-y-3 sm:gap-x-2">
        <IconLink to="https://nodejs.org/" target="_blank" icon={NodeIcon} className="flex-none">Node.js</IconLink>
        <IconLink to="https://github.com/vercel/pkg" target="_blank" icon={GitHubIcon} className="flex-none">Pkg</IconLink>
      </div>
      <p>
        We've all had the same problem before, we have a friend with a fleet of computers in their basement for LAN parties but making sure that the 
        appropriate steam games are downloaded and updated on all computers beforehand is a pain. This is where Steam Easy Download can come into play. 
        All you need to do is setup the config file and run Steam Easy Download on each computer and it will make sure the desired games are updated and downloaded. 
        Using Task Scheduler this can be setup to be run on a regular basis to keep each computer up to date.
      </p>
      <br />
      <IconLink to="steameasydownload" target="_self" icon={ReadMoreIcon} className="flex-none">Read More about Steam Easy Download</IconLink>
    </Article>
    </>
  )
}

export default AppDev;